
import { CompanyLogoWide } from '@components/CompanyLogoWide';
import { AppBar, Box, Container, Link, Toolbar, Typography } from '@mui/material';

export function DevotedTOS() {
  return (
    <>
      <AppBar position="sticky" sx={{ bgcolor: 'white' }}>
        <Box sx={{ width: '100%' }}>
          <Toolbar disableGutters sx={{ minHeight: '54px', px: 7.5 }}>
            <Link href="/" sx={{ display: { xs: 'none', md: 'flex' } }}>
              <CompanyLogoWide />
            </Link>
          </Toolbar>
        </Box>
      </AppBar>
      <Container disableGutters sx={{ px: { xs: 5, sm: 40 } }}>
        <Typography
          sx={{
            px: 8,
            display: 'flex',
            flexDirection: 'column',
            mt: { xs: 0, sm: 25 },
          }}
          variant="body1"
          component="div"
        >
          <Typography variant="h3">
            HeathSherpa Medicare Marketplace - Devoted Terms of Service
          </Typography>

          <p>
          Devoted Health is an HMO and/or PPO plan with a Medicare contract. Our D-SNPs also have contracts with State Medicaid programs. 
          Enrollment in our plans depends on contract renewal.  
          </p>

          <p>
            Devoted Health complies with applicable Federal civil rights laws and does not discriminate on the basis of race, color, 
            national origin, age, disability, or sex. <br /> 
            ATENCIÓN: Si habla español, tiene a su disposición servicios gratuitos de asistencia lingüística. Llame al{' '}
            <Link href="tel:18003386863">
              1-800-338-6833
            </Link>{' '}(TTY 711).
            <Link 
              href="https://www.devoted.com/nondiscrimination-notice/"
              target="_blank" 
              rel="noopener noreferrer"
            >
              <br />
              Devoted non-discrimination notice
            </Link>
          </p>

          <p>
            Benefits, premiums, and cost sharing may vary by plan.
          </p>

          <p>
            Dental limitations may apply. 
            Dental coverage may be in the form of a card, allowance, or reimbursement-based benefit.
          </p>

          <p>
            The Food & Home Card is a special supplemental benefit available only to chronically ill members 
            with eligible chronic health conditions, like diabetes, high blood pressure, high cholesterol, 
            heart problems, and stroke. Other conditions may be eligible. All applicable eligibility requirements 
            must be met before the benefit is provided. Not all members qualify. On D-SNP plans, you must 
            receive “Extra Help” to qualify for the Food & Home Card. 
          </p>

          <p>
            Out-of-network/non-contracted providers are under no obligation to treat Plan members, 
            except in emergency situations.Please call our customer service number or see your Evidence
            of Coverage for more information, including the cost-sharing that applies to out-of-network services.
          </p>

          <p>
            Every year, Medicare evaluates plans based on a 5-star rating system.
          </p>

          <p>
            According to the Paperwork Reduction Act of 1995, no persons are required to respond to a collection 
            of information unless it displays a valid OMB control number. The valid OMB control number for this 
            information collection is 0938-1378. The time required to complete this information is estimated to 
            average 20 minutes per response, including the time to review instructions, search existing data resources, 
            gather the data needed, and complete and review the information collection. If you have any comments 
            concerning the accuracy of the time estimate(s) or suggestions for improving this form, 
            please write to: 
            CMS, 7500 Security Boulevard, Attn: PRA Reports Clearance Officer, Mail Stop C4-26-05, Baltimore, Maryland 21244-1850.
          </p>

          <p>
            IMPORTANT Do not send this form or any items with your personal information (such as claims, payments, medical records, etc.)
            to the PRA Reports Clearance Office. Any items we get that aren&apos;t about how to improve this form or its collection burden 
            (outlined in OMB 0938-1378) will be destroyed. It will not be kept, reviewed, or forwarded to the plan. See “What happens next?”
            on this page to send your completed form to the plan.
          </p>


          <p>
            PRIVACY ACT STATEMENT The Centers for Medicare & Medicaid Services (CMS) collects information from Medicare plans to track 
            beneficiary enrollment in Medicare Advantage (MA) or Prescription Drug Plans (PDP), improve care, and for the payment of 
            Medicare benefits. Sections 1851 and 1860D-1 of the Social Security Act and 42 CFR §§ 422.50, 422.60, 423.30 and 423.32 
            authorize the collection of this information. CMS may use, disclose and exchange enrollment data from Medicare beneficiaries 
            as specified in the System of Records Notice (SORN) “Medicare Advantage Prescription Drug (MARx)”, System No. 09-70-0588. 
            Your response to this form is voluntary. However, failure to respond may affect enrollment in the plan.
          </p>

          <p>
            SilverSneakers, the SilverSneakers shoe logo type and SilverSneakers Flex are registered trademarks of 
            Tivity Health, Inc. © 2023 Tivity Health, Inc. All rights reserved.
          </p>

          <p>
            By submitting your information, you give Devoted Health permission to contact you to discuss plan options (phone, text, or email) 
            and you agree to our{' '}
            <Link 
              href="devoted.com/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions
            </Link>
            {'. '}Cellular carrier charges and data rates may apply. 
            Text frequency from Devoted Health varies. You can opt out at any time by calling{' '}
            <Link href="tel:18003386863">
              1-800-338-6833
            </Link>{' '}(TTY 711). 
            By text message, you can opt out by replying STOP. You can also reply HELP to get help. Opting out will not impact your ability 
            to enroll or receive services.
            <br />
            <Link 
              href="https://www.devoted.com/privacy-policy"
              target="_blank" 
              rel="noopener noreferrer"             
            >
              Devoted privacy notice
            </Link>
          </p>

        </Typography>
      </Container>
    </>
  );
}