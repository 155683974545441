import '@config/sentry';

import { MixpanelProvider, wrapRouteElements } from '@providers/MixpanelProvider';
import { MuiProvider } from '@providers/MuiProvider';
import { SWRProvider } from '@providers/SWRProvider';

import { Landing } from './pages/Landing';
import { Teaser } from './pages/Teaser';
import { TermsOfService } from './pages/TermsOfService';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { DevotedTOS } from './pages/DevotedTOS';

const routes = [
  {
    path: '/',
    element: Landing,
  },
  {
    path: '/terms_of_service',
    element: TermsOfService,
  },
  {
    path: '/devoted_terms_of_service',
    element: DevotedTOS,
  },
  {
    path: '/get_notified',
    element: Teaser,
  },
]

const router = createBrowserRouter(wrapRouteElements(routes));

export function App() {
  return (
    <MuiProvider>
      <SWRProvider>
        <MixpanelProvider>
          <RouterProvider router={router} />
        </MixpanelProvider>
      </SWRProvider>
    </MuiProvider>
  );
}
