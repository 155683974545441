import { CompanyLogoWide } from '@components/CompanyLogoWide';
import { AppBar, Box, Container, Link, Toolbar, Typography } from '@mui/material';

export function TermsOfService() {
  return (
    <>
      <AppBar position="sticky" sx={{ bgcolor: 'white' }}>
        <Box sx={{ width: '100%' }}>
          <Toolbar disableGutters sx={{ minHeight: '54px', px: 7.5 }}>
            <Link href="/" sx={{ display: { xs: 'none', md: 'flex' } }}>
              <CompanyLogoWide />
            </Link>
          </Toolbar>
        </Box>
      </AppBar>
      <Container disableGutters sx={{ px: { xs: 5, sm: 40 } }}>
        <Typography
          sx={{
            px: 8,
            display: 'flex',
            flexDirection: 'column',
            mt: { xs: 0, sm: 25 },
          }}
          variant="body1"
          component="div"
        >
          <Typography variant="h3">
            HeathSherpa Medicare Marketplace - Agent Terms of Use
          </Typography>

          <p>
            These HealthSherpa Medicare Marketplace - Agent Terms of Use shall apply to each
            individual agent (&quot;User&quot;) who accesses the HealthSherpa Medicare Marketplace
            Agent Platform (the &quot;Platform&quot;), whether through the User&apos;s own account
            on the Platform, or through the account of an agency with which the User is affiliated.
            Please read these Terms of Use carefully. By accessing or using the Platform, you accept
            and agree to be bound and abide by these Terms of Use as well as any policies, addenda
            or other documents incorporated herein by reference. If you do not want to agree to
            these Terms of Use, you must not access or use the Platform.
          </p>

          <p>1. LICENSE TO USE THE PLATFORM</p>

          <p>
            Subject to these Terms of Use, Geozoning, Inc. dba HealthSherpa
            (&quot;HealthSherpa&quot;) grants to User a limited, non-exclusive, non-assignable,
            non-transferable license to access and use the Platform only for the permitted purposes
            expressly set forth herein. Any other commercial use or exploitation of the Platform or
            any content, code, information, data or other materials transmitted through the Platform
            is strictly prohibited. In no event shall User use any trademark, trade name, service
            mark, icon, logo or other indicator of HealthSherpa without HealthSherpa&apos;s advance,
            express, written permission in each instance. All rights not expressly granted to User
            hereunder are reserved to HealthSherpa.
          </p>

          <p>2. CREATION AND USE OF ACCOUNT</p>

          <p>
            In order to access or use the Platform, you will be required to establish an account and
            obtain a login name and password (&quot;Account Credentials&quot;). You authorize
            HealthSherpa to process any account transactions initiated through the use of your
            Account Credentials and you accept responsibility for all activities that occur under
            your account. You are solely responsible for maintaining the confidentiality of your
            Account Credentials. You must immediately notify HealthSherpa of any unauthorized use of
            your Account Credentials, and you are responsible for any unauthorized activities
            resulting from the compromise of your Account Credentials. In no event will HealthSherpa
            be liable for the unauthorized use or misuse of your account or Account Credentials.
          </p>

          <p>
            You are prohibited from violating or attempting to violate the security of the Platform,
            including, without limitation, (a) accessing data not intended for such user or logging
            onto a server or an account which the user is not authorized to access; or (b)
            attempting to probe, scan or test the vulnerability of a system or network or to breach
            security or authentication measures without proper authorization; or (c) accessing or
            using the Platform or any portion thereof without authorization, in violation of these
            Terms of Use or in violation of applicable law.
          </p>

          <p>
            You agree not to share or disclose your Account Credentials to any third party. You
            agree that you are fully responsible for all activity occurring under your user ID. To
            the extent that you grant another party access to your account by sharing your Account
            Credentials, you will be responsible for any activities undertaken by such third party
            through the use of your account. User shall be responsible for any access to or use of
            the Platform by User or any person or entity using User&apos;s Account Credentials,
            whether or not such access or use has been authorized by User, and whether or not such
            person or entity is an employee or broker of User.
          </p>

          <p>
            You may not use any scraper, crawler, spider, robot or other automated means of any kind
            to access or copy data on the Platform or bypass our robot exclusion headers or other
            measures we may use to prevent or restrict access to the Platform. iFraming is strictly
            prohibited. You may deep link to a feature or content on the Site only with the prior
            written permission of both HealthSherpa and the Centers for Medicare & Medicaid Services
            (&quot;CMS&quot;).
          </p>

          <p>
            Violations of system or network security may result in civil or criminal liability.
            HealthSherpa will investigate occurrences that may involve such violations and may
            involve, and cooperate with, law enforcement authorities in prosecuting users who are
            involved in such violations. You agree not to use any device, software or routine to
            interfere or attempt to interfere with the proper working of this Site or any activity
            being conducted on this Site.
          </p>

          <p>
            HealthSherpa shall have the right, in its sole discretion, to deactivate a User&apos;s
            account or reset a User&apos;s Account Credentials if it reasonably determines that
            suspicious activity or misuse of the account has occurred.
          </p>

          <p>
            Any client information entered directly into the HealthSherpa platform by third-party
            users conducting their own enrollments will not be shared, sold, or used by HealthSherpa
            for any purpose other than troubleshooting. Data entered into the platform will only be
            accessed by HealthSherpa for platform troubleshooting purposes and only with the express
            permission of the third-party user.
          </p>

          <p id="tos-acceptable-use">3. ACCEPTABLE USE OF THE PLATFORM</p>

          <p>
            User may use the Platform only for lawful purposes and in accordance with these Terms of
            Use. You agree to be bound by the applicable provisions of the HealthSherpa Acceptable
            Use Policy, incorporated herein and made part of these Terms of Use by reference. You
            agree to conduct your business activities in a manner which complies with all applicable
            federal and state laws, conforms to the highest standards of business ethics, and
            maintains the good business reputation of HealthSherpa and its other customers. In
            particular, and without limitation, you agree not to do any of the following:
          </p>

          <ul>
            <li>
              Violate any state or federal law relating to the marketing or sale of insurance
              policies;
            </li>
            <li>
              Submit an application for insurance on behalf of a consumer for whom you have not
              obtained appropriately documented consent.
            </li>
            <li>
              Engage in false advertising or in any fraudulent or deceptive business practice;
            </li>
            <li>
              Violate the Telephone Consumer Protection Act (TCPA) by sending an auto-dialed,
              voice-recorded message, or SMS text to individuals who have not provided prior express
              written consent or are on the National Do Not Call Registry.
            </li>
            <li>
              Post, distribute, or otherwise make available or transmit any data, text, medium or
              computer file, telephonic conversations, chat or email exchanges that HealthSherpa, in
              its sole discretion, deems to be: (a) defamatory, abusive, harassing, insulting or
              threatening; (b) bigoted, hateful, or offensive; (c) vulgar, obscene, or sexually
              explicit; or (d) encouraging of or advocating illegal activity or discussing illegal
              activities with the intent to commit them;
            </li>
            <li>
              Post, distribute, or otherwise make available or transmit any data, text, medium or
              computer file that (a) infringes any right of a third party under any domestic or
              international law, including but not limited to copyright, patent, trademark, trade
              secret or other proprietary right; or (b) violates any right of privacy or publicity
              of a third party in the absence of such third party&apos;s express permission to
              disseminate his or her personal information, voice or likeness;
            </li>
            <li>
              Post, distribute or otherwise make available or transmit any software or files that
              contain a virus or other harmful component;
            </li>
            <li>
              Impersonate any person or entity or misrepresent your identity or affiliation with
              another person or entity;
            </li>
            <li>
              Delete any legal notices or disclaimers, including but not limited to copyright and
              trademark symbols, or modify any marks which you do not own or have express permission
              to modify.
            </li>
            <li>
              Use the Platform in any manner that could disable, overburden, damage, or impair the
              Platform or interfere with any other party&apos;s use of the Platform.
            </li>
            <li>
              Use any robot, spider or other automatic device, process or means to access the
              Platform for any purpose, including monitoring or copying any of the material on the
              Platform.
            </li>
            <li>
              Use any manual process to monitor or copy any of the material on the Platform or for
              any other unauthorized purpose without our prior written consent.
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of
              the Platform, the server on which the Platform is stored, or any server, computer or
              database connected to the Platform.
            </li>
            <li>Otherwise attempt to interfere with the proper working of the Platform.</li>
            <li>
              Behave in an inflammatory, hostile, belligerent, rude or disrespectful manner towards
              HealthSherpa&apos;s employees or any other individual through or in connection with
              the Platform.{' '}
            </li>
          </ul>

          <p>
            HealthSherpa shall have the right, in its sole discretion, to deactivate your account if
            it believes that you have done any of the above.
          </p>

          <p>4. PERSONALLY IDENTIFIABLE INFORMATION</p>

          <p>
            User shall ensure that any personally identifiable information (PII) collected or
            transmitted through the Platform by User was obtained with proper consent and authority.
            User shall implement appropriate safeguards to protect all PII in User&apos;s possession
            and control or that resides in User&apos;s account on the Platform and shall comply with
            all applicable federal and state law governing the use and disclosure of PII. User shall
            be responsible for any unauthorized use or disclosure of PII in User&apos;s possession
            and control or that resides in User&apos;s account on the Platform, except to the extent
            that such unauthorized use or disclosure of PII was directly caused by HealthSherpa (and
            provided that User has otherwise obtained proper consent and authority to collect or
            transmit such PII through the Platform), and shall notify affected individuals of such
            unauthorized use or disclosure in accordance with applicable law at User&apos;s sole
            cost and expense. User shall be solely responsible for unauthorized use or disclosure of
            PII resulting from a compromise of User&apos;s Account Credentials. User shall
            indemnify, defend, and hold HealthSherpa and its employees, agents, officers, and
            directors harmless from and against any claims, damages, fines, penalties, costs,
            expenses (including reasonable attorneys&apos; fees) or other liabilities arising out of
            or related to an unauthorized use or disclosure of PII resulting from (i) User&apos;s
            Account Credentials, (ii) User&apos;s failure to implement reasonable safeguards to
            protect the confidentiality of PII in User&apos;s possession, and (iii) any other cause
            not directly attributable to HealthSherpa. You consent to HealthSherpa storing,
            transmitting and receiving PII on your behalf for the purpose of facilitating
            &quot;Sales Activities&quot; as defined by the CMS Medicare Communications and Marketing
            Guidelines.
          </p>

          <p>5. HIPAA MUTUAL SUBCONTRACTOR BUSINESS ASSOCIATE AGREEMENT (&quot;BAA&quot;)</p>

          <p>
            Both User and HealthSherpa provides services for or on behalf of health plans and other
            entities in the healthcare industry (&quot;Covered Entities&quot;) that may require them
            to create, receive, maintain, or transmit individually identifiable health information
            that is protected by federal and state law (&quot;protected health information&quot; or
            &quot;PHI,&quot; as further defined below), and thus meet the definition of
            &quot;business associates&quot; under HIPAA. Furthermore, User and HealthSherpa may each
            be considered a subcontractor business associate to the other depending on the services
            performed by each party for the other that involve the use or disclosure of PHI.
            Pursuant to the Health Insurance Portability and Accountability Act of 1996, as amended
            by Subtitle D of the Health Information Technology for Economic and Clinical Health Act,
            enacted as part of the American Recovery and Reinvestment Act of 2009 (Pub. L. 111-5)
            (collectively, &quot;HIPAA&quot;) and its implementing regulations set forth at 45
            C.F.R. Parts 160-164, including but not limited to, the Standards for Privacy of
            Individually Identifiable Health Information (the &quot;Privacy Rule&quot;) and the
            Standards for Security of Electronic Health Information (the &quot;Security Rule&quot;),
            business associates must ensure that any subcontractors to whom they provide PHI
            obtained from, or created, received, maintained, or transmitted for or on behalf of
            Covered Entities, agree to the same restrictions and conditions that apply to business
            associate with respect to PHI. Therefore, to the extent that either User or HealthSherpa
            is acting as a business associate to the other, each party agrees to the following
            (&quot;Business Associate&quot; shall refer to both User and HealthSherpa, as
            applicable):
          </p>

          <ul>
            <li>
              Definitions:
              <ul>
                <li>
                  &quot;Breach&quot; means the unauthorized acquisition, access, use, or disclosure
                  of PHI not permitted by HIPAA which compromises the security, privacy, or
                  integrity of PHI.
                </li>
                <li>
                  &quot;Disclose&quot; and &quot;disclosure&quot; mean, with respect to PHI, to
                  release, transfer, provide access to, or divulge in any manner outside Business
                  Associate&apos;s internal operations.
                </li>
                <li>
                  &quot;Electronic protected health information&quot; or &quot;Electronic PHI&quot;
                  means protected health information that is transmitted by electronic media (as
                  defined by the Privacy Rule and Security Rule) or is maintained in electronic
                  media. Electronic PHI may be transmitted and maintained on devices such as cell
                  phones, PDAs, text pagers, and USB static discs.
                </li>
                <li>
                  &quot;Protected health information&quot; or &quot;PHI&quot; means information,
                  including demographic information, that (i) relates to the past, present, or
                  future physical or mental health or condition of an individual, the provision of
                  health care to an individual, or the payment for the provision of health care to
                  an individual; (ii) identifies the individual (or for which there is a reasonable
                  basis for believing that the information can be used to identify the individual);
                  and (iii) is created, received, maintained, or transmitted by Business Associate
                  or a Covered Entity. PHI includes, without limitation, Electronic PHI.
                </li>
                <li>
                  &quot;Secretary&quot; means the Secretary of the U. S. Department of Health and
                  Human Services or their designee.
                </li>
                <li>
                  &quot;Services&quot; means those activities, functions, or services that Business
                  Associate provides for, or on behalf of the other party.
                </li>
                <li>
                  &quot;Unsecured PHI&quot; means PHI that is not rendered unusable, unreadable, or
                  indecipherable to unauthorized individuals through use of a technology or
                  methodology specified in guidance by the Secretary.
                </li>
                <li>
                  &quot;Use&quot; or &quot;uses&quot; mean, with respect to PHI, the sharing,
                  employment, application, utilization, examination, or analysis of such PHI within
                  Business Associate&apos;s internal operations.
                </li>
                <li>
                  Terms used, but not otherwise defined in this BAA shall have the same meaning as
                  those terms are given in HIPAA.
                </li>
              </ul>
            </li>
            <li>
              Assurances by Business Associate Regarding PHI. Business Associate warrants that it
              shall comply with all provisions of the Privacy Rule and Security Rule that apply to
              it.
            </li>
            <li>
              Permitted Uses and Disclosures of PHI. Except as otherwise specified herein, Business
              Associate may use and disclose PHI as necessary to perform the Services for or on
              behalf of the other party, provided that such use or disclosure would not violate the
              Privacy Rule or Security Rule if done by the other party or a Covered Entity. You also
              consent to HealthSherpa storing, transmitting and receiving PHI on your behalf for the
              purpose of facilitating &quot;Sales Activities&quot; as defined by the CMS Medicare
              Communications and Marketing Guidelines. Business Associate shall use the minimum
              amount of PHI necessary to perform the Services. Business Associate agrees to:
              <ul>
                <li>
                  Not use or disclose PHI other than as permitted or required by this BAA, or as
                  required by law.
                </li>
                <li>
                  Implement appropriate administrative, physical and technical safeguards to prevent
                  unauthorized use or disclosure of PHI, and comply with the Security Rule with
                  respect to Electronic PHI to prevent use or disclosure of Electronic PHI other
                  than as provided for by this BAA.
                </li>
                <li>
                  Promptly report to the other party any use or disclosure of PHI not provided for
                  by this BAA of which it becomes aware, including Breaches of Unsecured PHI as
                  required at 45 CFR 164.410, and any security incident of which it becomes aware.
                  An unauthorized use or disclosure shall be treated as discovered by Business
                  Associate as of the first day on which such use or disclosure is known to Business
                  Associate, or by exercising reasonable diligence, should have been known to
                  Business Associate. Business Associate further agrees to fully cooperate in good
                  faith with and to assist the other party in complying with the requirements of
                  HIPAA with respect to an unauthorized use or disclosure of PHI.
                </li>
                <li>
                  Mitigate, to the extent practicable, any harmful effect that is known to Business
                  Associate of a use or disclosure of PHI, including Unsecured PHI, by Business
                  Associate in violation of the requirements of this BAA or HIPAA.
                </li>
                <li>
                  In accordance with 45 CFR 164.502(e)(1)(ii) and 164.308(b)(2), if applicable,
                  ensure that any subcontractors that create, receive, maintain, or transmit PHI on
                  behalf of Business Associate agree to the same restrictions, conditions, and
                  requirements that apply to Business Associate with respect to PHI.
                </li>
                <li>
                  Make its internal practices, books, and records available to the Secretary for
                  purposes of determining compliance with HIPAA.
                </li>
                <li>
                  If Business Associate carries out any of a Covered Entity&apos;s obligations under
                  the Privacy Rule pursuant to this BAA, Business Associate shall comply with
                  requirements of the Privacy Rule that would be applicable to the Covered Entity in
                  the performance of such obligations.
                </li>
                <li>
                  Absent prior written approval from the other party, Business Associate shall not
                  transfer PHI to any person or entity located outside the United States, including
                  Business Associate&apos;s employees, agents, representatives or subcontractors. A
                  transfer outside the United States occurs if Business Associate or its employees,
                  agents, representatives or subcontractors physically located outside the United
                  States are able to access, use, or disclose PHI maintained in the United States.
                </li>
              </ul>
            </li>
            <li>
              Treatment of PHI Upon Termination. Upon termination of the parties&apos; relationship,
              if it is infeasible to return or destroy PHI shared pursuant to these Terms of Use,
              Business Associate shall extend the protections of this BAA to such PHI and limit any
              further uses of such PHI to those purposes that make the return or destruction of the
              PHI infeasible. The obligations of this section shall survive termination of these
              Terms of Use and BAA.
            </li>
            <li>
              Use of PHI for Business Associate&apos;s Operations. Business Associate may use and/or
              disclose PHI to the extent necessary for Business Associate&apos;s proper management
              and administration, or to carry out Business Associate&apos;s legal responsibilities
              if:
              <ul>
                <li>The disclosure is required by law, or;</li>
                <li>
                  Business Associate obtains reasonable assurances from any person or organization
                  to which Business Associate shall disclose such PHI that such person or
                  organization shall (i) hold such PHI in confidence and use or further disclose it
                  only for the purpose for which Business Associate disclosed it to the person or
                  organization, or as required by law; and (ii) notify Business Associate of any
                  occurrence which the person or organization becomes aware of in which there was a
                  privacy or security incident and/or the confidentiality of such PHI was breached.
                </li>
              </ul>
            </li>
          </ul>

          <p>6. USER COMPLIANCE WITH APPLICABLE LAW</p>

          <p>
            User agrees to remain in compliance with all applicable federal and state laws
            including, but not limited to the following:
          </p>

          <ul>
            <li>
              All applicable Centers for Medicare & Medicaid Services (CMS) Guidelines, including
              but not limited to the CMS Medicare Communications and Marketing Guidelines;
            </li>
            <li>
              Federal and state laws governing the sale and marketing of insurance policies,
              including those governing licensure and appointment of insurance agents;
            </li>
            <li>
              Federal and state laws governing PII, including the Health Insurance Portability and
              Accountability Act of 1996 (HIPAA) and the Gramm-Leach-Bliley Act (GLBA), if
              applicable; and
            </li>
            <li>The Telephone Consumer Protection Act (TCPA).</li>
          </ul>

          <p>7. USAGE MONITORING AND AUDITING</p>

          <p>
            So that information provided through HealthSherpa&apos;s website remains accurate and
            available to you and all other visitors, we monitor network traffic to identify
            unauthorized attempts to upload or change information or otherwise cause damage to the
            website.
          </p>

          <p>8. ACCURATE INFORMATION</p>

          <p>
            You agree to provide certain true, current, complete and accurate information about you
            as required by law, and to maintain and update such information as needed to keep it
            current, complete and accurate. You agree that, to the best of your knowledge, all
            information you submit through the Platform is accurate and complete.
          </p>

          <p>
            You are solely responsible for the credit card or bank account information you provide
            to HealthSherpa and must promptly inform HealthSherpa of any changes thereto (e.g.,
            change of expiration date or account number). We are neither responsible for any
            consequences resulting from your failure to provide notice nor for your providing
            outdated, incomplete or inaccurate information.
          </p>

          <p>9. INTELLECTUAL PROPERTY</p>

          <p>
            The Platform and its entire contents, features and functionality, including but not
            limited to all information, software, text, displays, images, video and audio, and the
            design, selection and arrangement thereof (collectively, &quot;Content&quot;), are owned
            by HealthSherpa or other providers of such material and are protected by United States
            and international copyright, trademark, patent, trade secret and other intellectual
            property or proprietary rights laws.
          </p>

          <p>
            These Terms of Use permit you a limited license to access and use the Platform for the
            permitted purposes set forth herein. You may not reproduce, republish, reverse-engineer,
            copy, upload, download, display, modify, interfere with, circumvent, license, transmit,
            or distribute any feature of the Platform, including materials retrieved from the
            Platform, any security or access control mechanism, or the underlying code in any form
            or by any means or for any commercial or other purpose not expressly authorized under
            these Terms of Use. Nothing herein is meant to transfer any right or license to the
            underlying code of the Platform.
          </p>

          <p>
            If you print, copy, modify, download or otherwise use or provide any other person with
            access to any part of the Platform in violation of these Terms of Use, your right to use
            the Platform will cease and you must, at our option, return or destroy any copies of the
            materials you have made. No right, title or interest in or to the Platform or any
            content on the Platform is transferred to you, and all rights not expressly granted to
            you herein are reserved by HealthSherpa. Any use of the Platform not expressly permitted
            by these Terms of Use is a violation of these Terms of Use and may violate copyright,
            trademark, and other laws. If you choose to provide input and suggestions regarding
            problems with or proposed modifications or improvements to the Platform (
            <strong>&quot;Feedback&quot; </strong>), then you hereby grant HealthSherpa an
            unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right to
            exploit the Feedback in any manner and for any purpose, including to improve the
            Platform and create other products and services.
          </p>

          <p>10. TRADEMARKS</p>

          <p>
            HealthSherpa&apos;s name, logo, and all related names, logos, product and service names,
            designs and slogans are trademarks of HealthSherpa. You must not use such marks without
            the prior written permission of HealthSherpa. All other names, logos, product and
            service names, designs and slogans on the Platform are the trademarks of their
            respective owners. User is not permitted to use the name &quot;HealthSherpa&quot; in
            their business name, nor imply any affiliation with or endorsement by HealthSherpa on
            their website or in any of their business and promotional materials, whether hard copy,
            electronic, or on online platforms including but not limited to YouTube, Facebook, etc.
          </p>

          <p>11. USER REPRESENTATIONS AND WARRANTIES</p>

          <p>User represents, warrants and covenants that:</p>

          <ul>
            <li>
              User will use the Platform only for lawful purposes and in strict conformance with
              these Terms of Use.
            </li>
            <li>
              All information submitted through the Platform by User is accurate and complete.
            </li>
            <li>
              User is duly licensed in each jurisdiction in which User engages or transacts the
              business of insurance and will maintain such licensure in good standing.
            </li>
            <li>
              User has obtained the appropriate consents necessary to collect and maintain all PII
              that User transmits through the Platform.
            </li>
            <li>
              User has obtained appropriately documented consent from all consumers on whose behalf
              User submits an application for insurance through the Platform.
            </li>
            <li>
              User has appropriate safeguards in place to protect the confidentiality of all PII in
              User&apos;s possession or control.
            </li>
            <li>User shall remain in compliance with all applicable federal and state laws.</li>
          </ul>

          <p>12. ACCURACY OF CONTENT AND QUOTES</p>

          <p>
            Although HealthSherpa attempts to ensure the integrity and accuracy of the Platform, it
            makes no representations, warranties or guarantees whatsoever as to the correctness or
            accuracy of the Platform and Content thereon. It is possible that the Platform could
            include typographical errors, inaccuracies or other errors, and that unauthorized
            additions, deletions and alterations could be made to the Platform by third parties. In
            the event that an inaccuracy arises, please inform HealthSherpa so that it can be
            corrected. HealthSherpa reserves the right to unilaterally correct any inaccuracies on
            the Platform without notice. Information contained on the Platform may be changed or
            updated without notice. Additionally, HealthSherpa shall have no responsibility or
            liability for information or Content posted to the Platform from any non-HealthSherpa
            affiliated third party.
          </p>

          <p>
            Product information and descriptions contained on the Platform are not intended to be
            complete descriptions of all applicable terms, exclusions and conditions, but are
            provided solely for informational purposes. Please refer to the actual policy for
            complete details of coverage under a specific policy.
          </p>

          <p>13. NO MEDICAL ADVICE</p>

          <p>
            The content of the Platform, including without limitation, text, copy, audio, video,
            photographs, illustrations, graphics and other visuals, is for informational purposes
            only and does not constitute professional medical advice, diagnosis, treatment or
            recommendations of any kind. You should always seek the advice of your qualified
            healthcare professionals with any questions or concerns you may have regarding your
            individual needs and any medical conditions. HealthSherpa does not recommend or endorse
            any specific tests, physicians, products, procedures, opinions or other information that
            may be included on the Platform. Reliance on any information appearing on the Platform,
            whether provided by HealthSherpa, its content providers, insurance companies, its
            clients, visitors to the Platform or others, is solely at your own risk.
          </p>

          <p>14. PLATFORM AVAILABILITY</p>

          <p>
            You acknowledge that the Internet is neither owned nor controlled by any one entity. As
            a result, HealthSherpa cannot guarantee that any given User shall be able to access
            HealthSherpa&apos;s server at any given time. HealthSherpa represents that it shall make
            commercially reasonable efforts to ensure that its server is available as widely as
            possible and with as little service interruption as possible. HealthSherpa shall not be
            liable for any damages arising out of the unavailability of the Platform.
          </p>

          <p>15. PLATFORM SECURITY</p>

          <p>
            HealthSherpa has security measures in place that seek to mitigate risk and use
            commercially reasonable procedures and technologies to help protect the confidentiality
            of any PII collected through the Platform, in accordance with CMS requirements. However,
            no website can be absolutely protected against intentional or malicious intrusion
            attempts despite our precautions. Furthermore, HealthSherpa does not control the
            devices, computers, or network through which you access the Platform or over which you
            may choose to send PII or payment information to the Platform, and therefore cannot
            prevent potential interceptions or compromises to your information while in transit to
            the Platform. HealthSherpa makes no guarantee to User as to the security, integrity, or
            confidentiality of any information transmitted to or from the Platform. HealthSherpa is
            committed to safeguarding your information to the best of its ability but cannot
            guarantee the security of electronic communications or transmissions made over the
            Internet.
          </p>

          <p>
            HealthSherpa makes no representations whatsoever about any other website that you may
            access through the Platform. When you access a non-HealthSherpa website, please
            understand that it is independent from HealthSherpa, and that HealthSherpa has no
            control over the content on that website. In addition, a link to a non- HealthSherpa
            website does not mean that HealthSherpa endorses or accepts any responsibility for the
            content, or the use, of the linked website. It is up to you to take precautions to
            ensure that whatever you select for your use or download is free of such items as
            viruses, worms, Trojan horses, and other items of a destructive nature. If you decide to
            access any of the third party websites linked to the Platform, you do this entirely at
            your own risk.
          </p>

          <p>16. DISCLAIMER OF WARRANTIES</p>

          <p>
            THE PLATFORM, INCLUDING WITHOUT LIMITATION, ALL CONTENT, FUNCTION, MATERIALS AND
            SERVICES PROVIDED THROUGH THE PLATFORM ARE PROVIDED &quot;AS IS&quot; AND &quot;AS
            AVAILABLE.&quot; HEALTHSHERPA MAKES NO EXPRESS OR IMPLIED WARRANTIES OR GUARANTEES ABOUT
            THE PLATFORM. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, HEALTHSHERPA HEREBY
            DISCLAIMS ALL SUCH WARRANTIES WITH RESPECT TO THE PLATFORM, INCLUDING THE WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, ANY WARRANTY FOR
            INFORMATION, DATA, DATA PROCESSING SERVICES OR UNINTERRUPTED ACCESS, AND ANY WARRANTIES
            CONCERNING THE AVAILABILITY, ACCURACY, COMPLETENESS, USEFULNESS, OR CONTENT OF
            INFORMATION. HEALTHSHERPA DOES NOT WARRANT OR REPRESENT THAT ANY MATERIAL ON THE
            PLATFORM IS ACCURATE, COMPLETE, CURRENT, RELIABLE, OR ERROR-FREE OR THAT DEFECTS WILL BE
            CORRECTED. HEALTHSHERPA MAKES NO WARRANTY THAT THE PLATFORM WILL MEET USERS&apos;
            EXPECTATIONS OR REQUIREMENTS. NO INFORMATION OR MATERIALS, WHETHER ORAL OR WRITTEN,
            OBTAINED BY YOU THROUGH THE PLATFORM SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE
            HEREIN. IF YOU ARE DISSATISFIED WITH THE PLATFORM, YOUR SOLE REMEDY IS TO DISCONTINUE
            USING THE PLATFORM. YOU AGREE THAT YOUR USE OF THE PLATFORM IS SOLELY AT YOUR OWN RISK.
          </p>

          <p>
            WARRANTIES RELATING TO PRODUCTS OR SERVICES OFFERED, SOLD AND FACILITATED BY AND THROUGH
            HEALTHSHERPA ARE SUBJECT TO SEPARATE WARRANTY TERMS AND CONDITIONS, IF ANY, PROVIDED
            WITH OR IN CONNECTION WITH THE APPLICABLE PRODUCTS OR SERVICES.
          </p>

          <p>17. LIMITATION OF LIABILITY</p>

          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL HEALTHSHERPA OR ITS
            REPRESENTATIVES BE LIABLE FOR DAMAGES OF ANY KIND UNDER ANY LEGAL THEORY (WHETHER IN
            CONTRACT, TORT OR OTHERWISE), ARISING OUT OF OR IN CONNECTION WITH (A) YOUR USE OF THE
            PLATFORM; (B) YOUR INABILITY TO USE OR ACCESS THE PLATFORM; (C) UNAUTHORIZED USE OF YOUR
            ACCOUNT CREDENTIALS; (D) ANY INFORMATION, CONTENT OR FUNCTIONALITY OFFERED THROUGH THE
            PLATFORM; (E) ANY TRANSACTION CONDUCTED THROUGH OR FACILITATED BY THE PLATFORM; (F) ANY
            CLAIM ATTRIBUTABLE TO ERRORS, OMISSIONS, DEFICIENCIES OR OTHER INACCURACIES IN THE
            PLATFORM; (G) ANY UNAUTHORIZED ACCESS TO, USE, DISCLOSURE OR ALTERATION OF, YOUR
            TRANSMISSIONS OF DATA INCLUDING, WITHOUT LIMITATION, PERSONALLY IDENTIFIABLE
            INFORMATION; (H) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE PLATFORM; OR (I) ANY
            EVENT BEYOND HEALTHSHERPA&apos;S REASONABLE CONTROL. TO THE EXTENT ANY OF THE FOREGOING
            LIMITATIONS OF LIABILITY ARE NOT ENFORCEABLE, HEALTHSHERPA&apos;S MAXIMUM AGGREGATE
            LIABILITY IN RELATION TO ANY EVENT OR SERIES OF RELATED EVENTS WILL BE LIMITED TO ONE
            HUNDRED DOLLARS ($100). IN NO EVENT SHALL HEALTHSHERPA BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES EVEN IF HEALTHSHERPA HAS BEEN ADVISED OF
            THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT THAT A STATE DOES NOT PERMIT THE
            EXCLUSION OR LIMITATION OF LIABILITY AS SET FORTH HEREIN, HEALTHSHERPA&apos;S LIABILITY
            IS LIMITED TO THE EXTENT PERMITTED BY LAW IN SUCH STATES.
          </p>

          <p>18. INDEMNIFICATION BY USER</p>

          <p>
            You agree to release, indemnify, defend and hold HealthSherpa and its agents, employees,
            officers, directors, shareholders, contractors, affiliates and assigns harmless from all
            claims, damages, fines, penalties, costs, expenses (including reasonable attorneys&apos;
            fees), or any other liabilities relating to or arising out of (a) the breach of your
            representations, warranties and obligations under these Terms of Use; (b) use of the
            Platform other than as permitted by these Terms of Use by you or anyone using your
            Account Credentials, whether authorized by you or not; (c) infringement of any
            intellectual property or other proprietary right of any third party by you; (d)
            violation of HealthSherpa&apos;s{' '}
            <a href="/terms_of_service#tos-acceptable-use">Acceptable Use Policy</a> or other
            operating rules or policies communicated to you by HealthSherpa; (e) any information or
            data you supplied to HealthSherpa; or (f) unauthorized access, use or disclosure of PII
            in your possession or control except to the extent directly caused by HealthSherpa. The
            obligations set forth in this Section will survive any termination of User&apos;s
            account or these Terms of Use.
          </p>

          <p>19. SUSPENSION</p>

          <p>
            HealthSherpa may, in its sole discretion, suspend or terminate your account at any time,
            for any reason or no reason, with or without notice. You may terminate your account at
            any time by contacting us at{' '}
            <a href="mailto:agent_support@healthsherpa.com">agent_support@healthsherpa.com</a>.
          </p>

          <p>
            HealthSherpa reserves the right to modify or discontinue the Platform at any time
            (including by limiting or discontinuing certain features of the Platform), temporarily
            or permanently, upon reasonable notice to you. HealthSherpa will have no liability for
            any change to the Platform or any suspension or termination of your access to or use of
            the Platform.
          </p>

          <p>20. EFFECT OF ACCOUNT TERMINATION</p>

          <p>
            In the event you wish to terminate your account, it is your sole responsibility to
            export your own client data prior to termination. If your account is terminated by
            HealthSherpa due to behavior described in Section 3 above or a violation of
            HealthSherpa&apos;s{' '}
            <a href="/terms_of_service#tos-acceptable-use">Acceptable Use Policy</a>, you will not
            have an opportunity to export client data from your account prior to it being
            deactivated.
          </p>

          <p>21. AUTHORITY TO MODIFY PLATFORM AND SERVICES</p>

          <p>
            HealthSherpa may upgrade, modify, change or enhance the Platform and convert User to a
            new version of the Platform or revise these Terms of Use at any time in its sole
            discretion, with or without notice. Any such revision or change will be binding after
            posting of the revised Terms of Use or change to the Platform on HealthSherpa&apos;s
            website, or upon notification to you by e-mail. By continuing to use the Platform after
            any revision to these Terms of Use or change in the Platform, you agree to abide by and
            be bound by any such revisions or changes. If you do not agree to such revisions or
            changes, you may cancel your account by sending HealthSherpa a notice, as set forth
            herein, stating that you would like to cancel your account. Certain provisions of these
            Terms of Use may be superseded by expressly designated legal notices or terms located on
            particular pages within this Site.
          </p>

          <p>22. ELIGIBILITY TO USE THE PLATFORM</p>

          <p>
            The Platform is intended only for users who are 18 years of age or older and reside in
            the United States or any of its territories or possessions. By agreeing to these Terms
            of Use, you represent and warrant that: (a) you are at least 18 years old and reside in
            the United States or its territories; and (b) your use of the Platform complies with any
            and all applicable laws and regulations and you will use the Platform only as set forth
            in these Terms of Use. If you do not meet these eligibility requirements, you must not
            access or use the Platform.
          </p>

          <p>23. HEALTHSHERPA&apos;S USE OF DATA YOU PROVIDE THROUGH THE PLATFORM</p>

          <p>
            HealthSherpa may use information you provide through your account (such as your name,
            National Producer Number, carrier appointment settings, etc.) to display advertisements
            from select partners within your account on the Agent Platform for which HealthSherpa
            may receive compensation. In the event your account is terminated due to any violation
            of use detailed in Section 3, HealthSherpa reserves the right to share your name,
            National Producer Number, and the fact that your account was terminated with any
            insurance carriers with whom you have indicated that you are appointed and with any
            insurance regulators with jurisdiction over your license(s). HealthSherpa may also use
            and disclose to third parties de-identified, aggregated data from the Platform for
            reports, metrics, media releases, and any other uses permissible by law. In addition, if
            you submit enrollments for Medicare products through HealthSherpa&apos;s platform,
            HealthSherpa may collect your National Producer Number or carrier specific writing
            numbers in order to verify your &quot;ready to sell&quot; status for such Medicare
            products.
          </p>

          <p>24. GENERAL TERMS</p>
          <ul>
            <li>
              <strong>Entire Agreement.</strong> These Terms of Use, together with our{' '}
              <a href="/terms_of_service#tos-acceptable-use">Acceptable Use Policy</a>, any addenda
              attached hereto, constitute the entire agreement between you and HealthSherpa relating
              to your use of the Platform, and supersede any previous agreements, understandings,
              representations and warranties (both oral and written) with respect to your use of the
              Platform.
            </li>
            <li>
              <strong>Authority to be Bound.</strong> If you are an entity, organization, or
              company, the individual accepting these Terms of Use on your behalf represents and
              warrants that he or she has authority to bind you to these Terms of Use and you agree
              to be bound by these Terms of Use.
            </li>
            <li>
              <strong>Assignment.</strong> You may not assign or transfer these Terms of Use or your
              rights under these Terms of Use, in whole or in part, by operation of law or
              otherwise, without our prior written consent. We may assign these Terms of Use at any
              time without notice or consent.
            </li>
            <li>
              <strong>No Waiver.</strong> The failure to require performance of any provision of
              these Terms of Use will not affect our right to require performance at any other time
              after that, nor will a waiver by us of any breach or default of these Terms of Use, or
              any provision of these Terms of Use, be a waiver of any subsequent breach or default
              or a waiver of the provision itself.
            </li>
            <li>
              <strong>Use of Headings.</strong> Use of section headers in these Terms of Use is for
              convenience only and will not have any impact on the interpretation of any provision.
              Throughout these Terms of Use the use of the word &quot;including&quot; means
              &quot;including but not limited to.&quot;
            </li>
            <li>
              <strong></strong>Severability. If any part of these Terms of Use is held to be invalid
              or unenforceable, the unenforceable part will be given effect to the greatest extent
              possible, and the remaining parts will remain in full force and effect.
            </li>
            <li>
              <strong>Notices.</strong> Except as expressly provided otherwise herein, all notices
              to HealthSherpa shall be in writing (either paper or electronic format) and delivered
              either via email to{' '}
              <a href="mailto:agent_support@healthsherpa.com">agent_support@healthsherpa.com</a>, or
              via postal mail to HealthSherpa, Inc., ATTN: Customer Service, 1215 19th Street, 2nd
              Floor, Sacramento, CA, 95811. All notices to you shall be delivered to your mailing
              address or e-mail address as provided in your account information (as updated by you
              pursuant to these Terms of Use). You authorize us to contact you as our customer via
              telephone, at the number provided by you in your account information, e-mail or postal
              mail regarding information that we deem is of potential interest to you. Notices and
              announcements may include commercial e-mails, and other notices describing changes,
              upgrades, new products and services or other relevant matters.
            </li>
            <li>
              <strong>Dispute Resolution and Arbitration.</strong> You agree that disputes arising
              under these Terms of Use will be resolved by binding, individual arbitration, and by
              accepting these Terms of Use, you and HealthSherpa are each waiving the right to a
              trial by jury or to participate in any class action or representative proceeding. Your
              rights will be determined by a neutral arbitrator instead of a judge or jury. Any
              arbitration between you and HealthSherpa will be settled under the Federal Arbitration
              Act and administered by the American Arbitration Association (&quot;AAA&quot;) under
              its Consumer Arbitration Rules (collectively, &quot;AAA Rules&quot;). Notwithstanding
              the foregoing, nothing in these Terms of Use will be deemed to waive, preclude, or
              otherwise limit the right of either party to: (a) pursue an enforcement action through
              the applicable federal, state, or local governmental agency if that right of action is
              available; (b) seek injunctive relief in a court of law in aid of arbitration; or (c)
              to file suit in a court of law to address an intellectual property infringement claim.
            </li>
            <li>
              <strong>Governing Law.</strong> These Terms of Use are governed by the laws of the
              State of California without regard to conflicts of law principles. You and
              HealthSherpa agree to submit to the personal and exclusive jurisdiction of the state
              courts and federal courts located within Sacramento County, California for resolution
              of any lawsuit or court proceeding permitted under these Terms of Use.
            </li>
            <li>
              <strong>Exclusion of Third-Party Rights.</strong> Except as otherwise set forth in
              these Terms of Use, these Terms of Use are for the benefit of you and HealthSherpa and
              are not intended to benefit any third party or be enforceable by any third party. The
              exercise of our and your rights in relation to these Terms of Use is not subject to
              the consent of any third party.
            </li>
            <li>
              <strong>Privacy Policy.</strong> Please read our{' '}
              <a href="https://www.healthsherpa.com/privacy_notice">Privacy Notice</a> for
              information relating to our collection, use, storage, and disclosure of personal
              information and payment information. The Privacy Notice is incorporated by this
              reference into, and made a part of, these Terms of Use.
            </li>
            <li>
              <strong>Additional Terms.</strong> Your use of the Platform is subject to all
              additional terms, policies, rules, or guidelines applicable to the Platform or certain
              features of the Platform that we may communicate to you (the &quot;Additional
              Terms&quot;). All Additional Terms are incorporated by this reference into, and made a
              part of, these Terms of Use.
            </li>
            <li>
              <strong>Consent to Electronic Communications.</strong> By providing us with your
              contact information, you agree to receive communications, including via e-mail, voice
              calls, and text messages from or on behalf of HealthSherpa at the email address or
              telephone number you provided, even if that number is on a national or state &quot;Do
              Not Call Registry.&quot; These calls will be for purposes related to your use of the
              Platform or our other services, such as to provide you with help or information on
              using the Platform. Standard text messaging and telephone minute charges applied by
              your cell phone carrier may apply. HealthSherpa may, without further notice or warning
              and in our discretion, monitor and/or record telephone conversations for our business
              purposes, such as quality assurance and training purposes and to protect our rights
              and the rights of others. IF YOU WISH TO OPT OUT OF MARKETING EMAILS, YOU CAN
              UNSUBSCRIBE BY FOLLOWING THE UNSUBSCRIBE OPTIONS IN THE EMAIL ITSELF. IF YOU WISH TO
              OPT OUT OF TEXTS, YOU MAY REPLY &quot;STOP&quot; FROM THE MOBILE DEVICE RECEIVING THE
              MESSAGES. You understand that you may continue to receive communications while we
              process your opt-out request, and you may also receive a communication confirming the
              receipt of your opt-out request.
            </li>
          </ul>
        </Typography>
      </Container>
    </>
  );
}
